<template>
  <div class="login">
    <img class="bg" :src="require('@/assets/images/dlbg.png')" alt="" />
    <div class="mian-con">
      <img class="dlbt" :src="require('@/assets/images/dlbt.png')" alt="" />
      <div class="login-con">
        <img :src="require('@/assets/images/yhdl.png')" alt="" />
        <div class="login-box">
          <div class="loginbg">
            <img
              class="input-img"
              :src="require('@/assets/images/input.png')"
              alt=""
            />
            <div class="input-con">
              <img :src="require('@/assets/images/zhanghao.png')" alt="" />
              <input v-model="userName" placeholder="输入登陆账号" />
            </div>
          </div>
          <div class="loginbg">
            <img
              class="input-img"
              :src="require('@/assets/images/input.png')"
              alt=""
            />
            <div class="input-con">
              <img :src="require('@/assets/images/pwd.png')" alt="" />
              <input
                v-model="password"
                :password="!password"
                placeholder="输入登陆密码"
                type="password"
                name="password"
                id="demo_input"
              />
              <a-icon
                v-if="!showpass"
                @click="hideShowPsw"
                type="eye-invisible"
                id="demo_img"
              />
              <a-icon
                id="demo_img"
                v-if="showpass"
                @click="hideShowPsw"
                type="eye"
              />
            </div>
          </div>
          <div @click="handleSubmit" class="loginbg-t">
            <span v-if="!Loading">登陆</span>
            <a-spin size="large" v-if="Loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginApi from "@/request/api/user.js";
import storage from "@/storage/index.js";
import MutationType from "@/store/mutation-type.js";
import { Progress, Tooltip, message, spin } from "ant-design-vue";
export default {
  name: "Login",
  data() {
    return {
      isPassingLock: false,
      userName: "",
      submitLoading: false,
      form: this.$form.createForm(this, { name: "login" }),
      password: "",
      tipsInfo: "",
      showPwdVis: false,
      loginErrorTimerf: null,
      Loading: false,
      /**默认密文 密码显示 */
      showpass: false,
      Loading: false,
    };
  },
  components: {
    "a-spin": spin,
  },
  methods: {
    handleSubmit(e) {
      if (this.loginErrorTimerf) {
        clearTimeout(this.loginErrorTimerf);
      }
      this.loginErrorTimerf = setTimeout(() => {
        if (this.Loading) {
          return;
        }
        this.getLogin();
      }, 500);

      // e.preventDefault();
      // this.form.validateFields(async (err, values) => {

      // });
    },
    async getLogin() {
      this.Loading = true;
      if (!this.userName || !this.password) {
        this.submitLoading = true;
        this.tipsInfo = "账号密码不能为空";
        return;
      }
      let params = {
        username: this.userName,
        password: this.password,
      };
      try {
        let res = await loginApi.login(params);
        this.Loading = false;
        let { token } = res;
        this.$store.commit(MutationType.SET_TOKEN_MUTATION, token);
        storage.setToken(token);
        this.getInfo();
      } catch (error) {
        console.log(error);
        this.Loading = false;
        this.$message.error(error.msg);
      }
    },
    async getInfo() {
      try {
        let info = await loginApi.info();
        this.$store.commit("setUserInfo", JSON.stringify(info.meshUser));
        this.$router.push({
          path: "/datav",
        });
      } catch (error) {
        console.log(error.msg);
      }
    },
    hideShowPsw() {
      this.showpass = !this.showpass;
      var demoImg = document.getElementById("demo_img");
      var demoInput = document.getElementById("demo_input");
      if (demoInput.type == "password") {
        demoInput.type = "text";
        demoImg.src = "invisible.png";
      } else {
        demoInput.type = "password";
        demoImg.src = "visible.png";
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
